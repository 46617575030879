import React from 'react';
import { useState } from 'react';
import Navegacao from './Navegacao'
import './assets/app.min.css';
import SVGSprites from './assets/icons/svg-sprites';
import icon4 from './assets/icons/icon-4.png';

import image_video_principal from './assets/curso-sushi.png';
import images_testimonial_1 from './assets/depoimento-curso-sushi-1.png';
import images_testimonial_2 from './assets/depoimento-curso-sushi-2.png';
import images_testimonial_3 from './assets/depoimento-curso-sushi-3.png';

function App() {

	const windowUrl = window.location.pathname;

	//Variaveis importantes. Não deletar
	let cursoLink = 'https://go.hotmart.com/U14593644G?ap=c9ce'
	const diaLimite = "Dec 11, 2024 20:59:59"

	if (windowUrl) {
		if (windowUrl.indexOf("promocao") > -1) {
			cursoLink = 'https://go.hotmart.com/U14593644G?ap=c9ce'
		}
		if (windowUrl.indexOf("desconto") > -1) {
			cursoLink = 'https://go.hotmart.com/U14593644G?ap=c9ce'
		}
	}

	// Countdown
	var deadline = new Date(diaLimite).getTime(); 
	var x = setInterval(function() { 
	var now = new Date().getTime(); 
	var t = deadline - now; 
	var days = Math.floor(t / (1000 * 60 * 60 * 24)); 
	var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60)); 
	var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)); 
	var seconds = Math.floor((t % (1000 * 60)) / 1000); 
	document.getElementById("countdown").innerHTML = 
	"<div class='wrapper'><div class='valor'>" + days + "</div><div class='label'>Dias</div></div><div class='wrapper'><div class='valor'>"  + hours + "</div><div class='label'>Horas</div></div><div class='wrapper'><div class='valor'>" + minutes + "</div><div class='label'>Minutos</div></div><div class='wrapper'><div class='valor'>" + seconds + "</div><div class='label'>Segundos</div></div>"; 
			if (t < 0) { 
					clearInterval(x); 
					document.getElementById("countdown").innerHTML = "<div class='turma-fechada'>Turma fechada 🙁. <br>Aguarde a próxima ou faça sua matrícula em um dos links da página.</div>"; 
			} 
	}, 1000);


	return (
		<div className="App">

			<Navegacao linkDoCurso={cursoLink}></Navegacao>

			<div className="hero">
				<div className="center">
					<h1 style={{color: "#f38c59"}}>
						Descubra como você pode se tornar um Sushiman de sucesso e lucrar muito!
					</h1>
					<h2 style={{color: "#333"}}>
						Você pode ter excelentes resultados começando hoje!
					</h2>
					<ButtonPrimary link={cursoLink} texto="QUERO GARANTIR MINHA VAGA"></ButtonPrimary>
				</div>
			</div>

			<SVGSprites/>
			
			<VideoSection>
				<ButtonPrimary link={cursoLink} texto="QUERO GARANTIR MINHA VAGA"></ButtonPrimary>
			</VideoSection>

			<Apresentacao>
				<ButtonPrimary link={cursoLink} texto="INICIAR AS AULAS"></ButtonPrimary>
			</Apresentacao>

			<Countdown>

			</Countdown>

			<BulletsSection1>
				<ButtonPrimary link={cursoLink} texto="QUERO COMEÇAR A APRENDER TUDO ISSO"></ButtonPrimary>
			</BulletsSection1>

			<BulletsSection2>
				<ButtonPrimary link={cursoLink} texto="INICIAR AULAS"></ButtonPrimary>
			</BulletsSection2>

			<TextSection1></TextSection1>

			<IconsSection1></IconsSection1>

			<CtaSection1>
				<ButtonPrimary link={cursoLink} texto="QUERO GARANTIR MINHA VAGA AGORA"></ButtonPrimary>
				<span>* VAGAS LIMITADAS!</span>
			</CtaSection1>

			<IconsSection2></IconsSection2>

			<TextSection2></TextSection2>

			<Whatsapp></Whatsapp>

		</div>
	);
}

function ButtonPrimary(props) {
	return (
		<a href={props.link} className="btn-primario btn-grande" role="button">{props.texto}</a>
	);
}


function VideoSection(props) {
	return (
			<div className="video-section">
				<div className="container">
					<ButtonVideo ValorImage={image_video_principal} width="1100" height="600" ValorVideo="https://www.youtube.com/embed/LmeSXjwq_BE?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=0&amp;playsinline=1&amp;showinfo=0&autoplay=1&amp;rel=0&amp;enablejsapi=1"></ButtonVideo>
					<h2><svg className="icon">
				<use href="#lock"></use>
			</svg> O que você irá aprender no curso de Sushi</h2>
					<p>Faça renda extra seguindo as técnicas milenares da cozinha japonesa! Voltado para você, que ainda não tem qualquer domínio desta arte oriental (por enquanto). O sushi, principal prato nipônico, será o astro deste curso, que envolve: a escolha do Salmão (e demais insumos); as diversas etapas de confecção da peça; o corte do sushi; a arrumação (acabamento final) para o consumo de seus futuros clientes. De forma prática e detalhada, ensinaremos também importantes técnicas de higiene, garantindo sucesso no seu negócio! O curso possui 21 vídeo-aulas mais 5 vídeo aulas de bônus, onde você poderá ver e rever quantas vezes quiser durante 1 ano. Seja um sushiman (ou sushi-woman) conceituado e mãos ao peixe!</p>
					{props.children}
				</div>
			</div>
	);
}

function TextSection1(props) {
	return (
		<div className="text-section">
				<div className="container">
					<h2>PARA QUEM O CURSO FOI FEITO?</h2>
					<p>O conteúdo do curso vai do básico ao avançado de forma gradativa com todas vídeo-aulas em HD para ver e rever no momento que quiser. É para quem ainda não sabe nada de Culinária Japonesa e também para quem já tem alguma experiência, mas busca evoluir ainda mais. </p>
					<p>Se você quer aprender a preparar sushis ou se aperfeiçoar através da culinária japonesa, começar um negócio do zero, preparar sushi por hobby ou trabalhar como sushiman, este curso foi feito para você! </p>
					<p>Montamos nosso negócio do zero (em conhecimento e em dinheiro) e atuamos como sushiman e sushi-woman em nosso delivery, e vamos ensinar <strong>TODOS OS NOSSOS CONHECIMENTOS NA PRÁTICA</strong> para você poder atuar das mesmas formas e ter sucesso com a culinária japonesa.</p>
					{props.children}
				</div>
			</div>
	)
}


function Apresentacao(props) {
	
	return (
		<div className="text-section orange">
				<div className="container">
					<h2>Comprovados por mais de 1.500 Alunos...</h2>
					<p>Aqui estão depoimentos de pessoas reais, de negócios reais, que estavam na sua posição há algum tempo atrás e tomaram a decisão de entrar no Curso de Sushi Online.</p>
					<div className="videos">
						<ButtonVideo width="560" height="315" ValorImage={images_testimonial_1} ValorVideo="https://www.youtube.com/embed/i7vtBjleIDg?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=0&amp;playsinline=1&amp;showinfo=0&autoplay=1&amp;rel=0&amp;enablejsapi=1"></ButtonVideo>
						<ButtonVideo width="560" height="315" ValorImage={images_testimonial_2} ValorVideo="https://www.youtube.com/embed/p4IbN68mHb0?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=0&amp;playsinline=1&amp;showinfo=0&autoplay=1&amp;rel=0&amp;enablejsapi=1"></ButtonVideo>
						<ButtonVideo width="560" height="315" ValorImage={images_testimonial_3} ValorVideo="https://www.youtube.com/embed/Ut0OdjKMnFc?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=0&amp;playsinline=1&amp;showinfo=0&autoplay=1&amp;rel=0&amp;enablejsapi=1"></ButtonVideo>
					</div>
					{props.children}
				</div>
			</div>
	)
}

function ButtonVideo(props) {
	const [modalVideo, setModalVideo] = useState(true);
	let videoContent
	if (modalVideo) {
		videoContent = <div className="playImage"><img alt="Curso de Sushiman" src={props.ValorImage} width={props.width} height={props.height}/></div>
	} else {
		videoContent = <iframe 
											title="Culinária Japa - Curso de Sushi" 
											width={props.width} 
											height={props.height} 
											src={props.ValorVideo} 
											frameBorder="0" 
											allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
											allowFullScreen
											allowtransparency="true"
											autoPlay
									></iframe>
	}
	function openVideo() {
		if (modalVideo) {
			setModalVideo(false)
		}
	}
	return (
		<button className="botaoVideo" onClick={() => openVideo(props.ValorImage)} aria-label="Abrir Video do Youtube" roles="button">
			{videoContent}
		</button>
	)
}

function Countdown(props) {
	return (
		<div className="text-section purple">
				<div className="container">
					<h2>
						<svg className="icon">
							<use href="#lock"></use>
						</svg>
						Essa promoção com bônus termina em:
					</h2>
					<div id="countdown"></div>
				</div>
			</div>
	)
}

function TextSection2(props) {
	return (
		<div className="text-section-2">
				<div className="container">
					<h2>Aprenda uma Maneira Simples de Você se Tornar um Empreendedor de Sucesso da Culinária Japonesa</h2>
					<ul>
						<li><p>Conseguirá a sua tão Sonhada Independência Financeira. </p></li>
						<li><p>Aprenderá a Fazer Sushis Perfeitos com a Qualidade Desejada pelo Mercado. </p></li>
						<li><p>Descobrirá como Abrir um Negócio de Culinária Japonesa. </p></li>
						<li><p>Aprenderá a Fazer as Primeiras Vendas. </p></li>
						<li><p>Poderá Fazer O que Realmente Ama.</p></li>
						<li><p>Pode Realizar o Seu Sonho de Ter o Próprio Negócio.</p></li>
						<li><p>Se Estiver Desempregado, Poderá Criar o seu Próprio Emprego.</p></li>
						<li><p>Poderá Ser o Delivery de Sushis Pioneiro da sua Cidade.</p></li>
						<li><p>Se Já Tem Emprego, Conseguirá Criar uma Fonte de Renda Extra para Você e Sua Família.</p></li>
						<li><p>Vai Compreender a Diferença Entre o Sushiman Profissional e Sushiman Empreendedor.</p></li>
					</ul>
					{props.children}
				</div>
			</div>
	)
}

function IconsSection1(props) {
	return (
		<div id="caracteristicas" className="icons-section">
				<div className="container">
					<h2>Características especias do curso de sushi online</h2>
					<div className="bullets">
						<div className="bullets-item">
						<svg className="icon">
							<use href="#certified"></use>
						</svg>
							Certificado de conclusão.
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#sushi"></use>
							</svg>
							Compra totalmente segura
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#shield"></use>
							</svg>
							Vídeos em alta resolução.
						</div>
					</div>
				</div>
			</div>
	)
}

function IconsSection2(props) {
	return (
		<div className="icons-section icons-section-2">
				<div className="container">
					<h2>Pagamento seguro e garantia</h2>
					<div className="bullets">
						<div className="bullets-item">
							<svg className="icon">
								<use href="#whatsapp"></use>
							</svg>
							<h3>Pagamento seguro</h3>
							O pagamento é feito numa plataforma segura chamada Hotmart. Todos os seus dados serão encriptados e sigiloso. É uma plataforma 100% segura, de confiança e líder no Brasil e América do Sul.
						</div>
						<div className="bullets-item">
							<img className="icon" src={icon4} alt="Culinária Japa"/>
							<h3>Acesso imediato</h3>
							Pagamento efectuados com cartão de crédito garantem o acesso imediato ao curso. Pagamentos com o boleto bancário apenas terá acesso no momento da confirmação do pagamento do boleto. Nenhum produto será enviado para a sua casa, pois trata-se de um curso 100% online e o acesso é dado por email.
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#sushi"></use>
							</svg>
							<h3>Garantia</h3>
							Confiamos plenamente que irá gostar do curso, que dispomos de uma garantia de 7 dias. Se ficou com alguma dúvida não hesite em conctatar-nos para o seguinte email: contato@post4post.com
						</div>
					</div>
				</div>
			</div>
	)
}


function CtaSection1(props) {
	return (
		<div id="preco" className="cta-section">
				<div className="container">
					<h2>A sua oportunidade de ter um negócio lucrativo, com baixo investimento.</h2>
					<h3>R$97,00</h3>
					<h4>Em até 10 x R$11,08</h4>
					{props.children}
				</div>
			</div>
	)
}

function BulletsSection1(props) {
	return (
			<div id="Modulos" className="bullets-section em-linha">
				<div className="container">
					<h2>Módulos após adquirir o curso</h2>
					<div className="bullets">
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 1: Quais utensílios usar?</h3>
							<p>Hoje em dia a concorrência está cada vez mais acirrada. Sendo assim, precisamos apresentar ótimos produtos, e para ter essa qualidade, precisamos dos utensílios corretos!</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 2: Como escolher o Salmão</h3>
							<p>Você já reparou como as pessoas não sabem escolher corretamente o salmão? Neste módulo você vai aprender à fazer a escolha correta para não ter dor de cabeça com os clientes.</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 3: Limpando o Salmão</h3>
							<p>Existem muitos jeitos para limpar o salmão, mas neste módulo terá o modo realmente correto, onde você pode começar e que vai te ajudar a iniciar e promover o seu trabalho e produto.</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 4: Como cortar o sashimi</h3>
							<p>O Curso vai ensinar a melhor forma de corte, bem como quais são as facas mais indicadas para esta função. Aprenda com a gente como cortar sashimi e garanta um maravilhoso sushi.</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 5: Preparação do arroz</h3>
							<p>Como fazer arroz para a comida japonesa como sushi, hot philadelphia, temaki e outros rolls</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 6: Preparação do tempero</h3>
							<p>Tão importante quanto o modo de preparo, os temperos e condimentos, quando utilizados da maneira certa, transformam qualquer sushi e trazem novas sensações ao paladar, aguçando os sabores e transformando a degustação em uma experiência surpreendente!</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 7: Montando Hossomaki</h3>
							<p>Como fazer o hossomaki (enrolado fino), passo a passo super simples, basta assistir os videos e reproduzir tal como mostrado no curso.</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 8: Montando Uramaki</h3>
							<p>Diferente do hossomaki, o uramaki é o sushi em que a alga fica por dentro e o arroz por fora, por isso, é importante prestar atenção na maneira como o sushi deve ser enrolado. Você vai aprender tudo neste módulo!</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 9: Montando Hot Philadelphia</h3>
							<p>Neste módulo, você vai aprender a montar o “queridinho” dos brasileiros com toda qualidade possível e suas curiosidades para obter um diferencial diante dos concorrentes!</p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>MÓDULO 10: Arrumando os combinados</h3>
							<p>O combinado japonês está entre os mais pedidos na culinária. Certamente por oferecer variedades de peixes e sabores. Este módulo irá ensinar a preparar combinados de alta conversão e fidelização.</p>
						</div>
					</div>
					{props.children}
				</div>
			</div>
	);
}


function Whatsapp() {
	return (
		<a className="botao_chamar_whatsapp" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5519993351720&amp;text=Ol%C3%A1.%20Gostaria%20de%20tirar%20d%C3%BAvidas%20sobre%20o%20curso%20de%20sushi">
			<svg className="icon animacao-whatsapp">
				<use href="#video"></use>
			</svg>
		</a>
	)
}

function BulletsSection2(props) {
	return (
			<div className="bullets-section em-linha">
				<div className="container">
					<h2>GANHE MAIS <strong>7 BÔNUS</strong> INCRÍVEIS AO SE <br/>TORNAR NOSSO ALUNO, AINDA HOJE!</h2>
					<div className="bullets">
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 1 LIMPANDO O CAMARÃO</h3>
							<p>Normalmente, encontramos dificuldades para limpar o camarão, mas, agora, com o módulo bônus do Curso de Sushi Online, vai ficar bem mais fácil! <strike>R$49,99</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 2 MONTANDO HARUMAKI</h3>
							<p>Harumaki, pastel chinês ou, como é mais conhecido no Brasil, rolinho primavera. Você vai aprender como montar o harumaki com ótima qualidade e aparência profissional. <strike>R$23,44</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 3 MONTANDO TEMAKI</h3>
							<p>Um tutorial completo de como fazer e montar o temaki! Com um vídeo do passo a passo. <strike>R$55,50</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 4 FRITANDO A PELE</h3>
							<p>Vamos mostrar, neste módulo, o que você deve e o que não deve fazer no momento de fritar a pele. <strike>R$15,78</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 5 FRITANDO O CAMARÃO</h3>
							<p>Aprenda a fritar o camarão da maneira correta quando se trata de culinária japonesa. <strike>R$11,57</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 6 PRODUTOS UTILIZADOS</h3>
							<p>Não é nenhum segredo que bons produtos são necessários para que o resultado final seja de extrema qualidade. Neste módulo, explico todos os produtos utilizados. <strike>R$34,78</strike></p>
						</div>
						<div className="bullets-item">
							<svg className="icon">
								<use href="#correct"></use>
							</svg>
							<h3>#BÔNUS 7 DICAS PARA COMEÇAR SEU NEGÓCIO</h3>
							<p>Um módulo especial com dicas para quem vai iniciar nos negócios da culinária japonesa. Neste módulo, conto minhas experiências, tudo que passei para chegar ao nível que estou hoje e damos dicas de como agir diante dessa nova etapa. R$ INCALCULÁVEL</p>
						</div>
					 
					</div>
					{props.children}
				</div>
			</div>
	);
}




export default App;
